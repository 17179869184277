app.controller('UPSController', function($scope, $rootScope, $routeParams, $modal, $timeout, UPS) {
    $scope.Options = {};
    var ups = new UPS;
    ups.$allOptions(function(result) {
        $scope.Options = result;
    });
    var targets = ['Shipper', 'ShipTo', 'ShipFrom'];
    var allowed_code = ['US', 'CA'];
    var empty_target = function() {
        return {
            Label: false,
            US: false,
            CA: false
        };
    };
    $scope.StateProvinceCode = {};
    for (var i in targets) {
        var target = targets[i];
        $scope.StateProvinceCode[target] = empty_target();
    }
//    $scope.DisplayMenu = function() {
//        $mdBottomSheet.show({
//            templateUrl: '/view/menu/ups.html',
//            controller: function($scope, $location) {
//                $scope.Redirect = function(path) {
//                    $location.path(path);
//                };
//            }
//        });
//    };
    $scope.RateForm = function() {
        $scope.Rate = getDefaultRateData();
    };
    $scope.RateFormSubmit = function() {
        $rootScope.RateResult = null;
        $rootScope.RateResultError = null;
        $scope.DisplayRateResult($scope.Rate, $scope.Options, $scope.StateProvinceCode);
//        $scope.Rate.Shipment.ShipTo.Address.StateProvinceCode = 'OUSCAR';
        var ups = new UPS($scope.Rate);
        ups.$rate(function(result) {
            $rootScope.Rate = $scope.Rate;
            $rootScope.RateResult = result;
        }, function(error) {
            $rootScope.RateResultError = error.data || null;
        });
    };
    $scope.DisplayRateResult = function(rate, options, stateProvinceCode) {
        $modal.open({
            windowClass: 'rate_modal',
            templateUrl: '/view/ups/modal/rate_result.html',
            controller: function($scope, $rootScope, $modalInstance) {
                $scope.Rate = rate;
                $scope.RateResult = null;
                $scope.RateResultError = null;
                $scope.Options = options;
                $scope.StateProvinceCode = stateProvinceCode;
                $rootScope.$watch('RateResult', function() {
                    $scope.RateResult = $rootScope.RateResult;
                });
                $rootScope.$watch('RateResultError', function() {
                    $scope.RateResultError = $rootScope.RateResultError;
                });
                $scope.Close = function() {
                    $modalInstance.close();
                };
            }
        });
    };
//	$scope.ShipForm = function() {
//		$scope.Ship = getDefaultRateData();
//	};
    $scope.CountryWatch = function(name) {
        $scope.$watch('Rate.Shipment.' + name + '.Address.CountryCode', function() {
            $scope.CountryChange(name);
        });
    };
    $scope.CountryChange = function(name) {
        var code = $scope.Rate.Shipment[name].Address.CountryCode || null;
        var target = $scope.StateProvinceCode[name] || null;
        if (target !== null) {
            target = empty_target();
            if (allowed_code.indexOf(code) > -1) {
                target.Label = true;
                target[code] = true;
            }
            $scope.StateProvinceCode[name] = target;
            $scope.Rate.Shipment[name].Address.StateProvinceCode = '';
        }
    };
    $scope.DisplayDimensions = function(field) {
        var allowed_code = ['02'];
        if (allowed_code.indexOf(field) > -1) {
            return true;
        }
    };
    $scope.InputSampleData = function() {
        $scope.Rate = {
            PickupType: {
                Code: '01'
            },
            Shipment: {
                Service: {
                    Code: '07'
                },
                Shipper: {
                    Name: 'GCR.inc',
                    Address: {
                        AddressLine: ['13F-2, No. 760, Bade Rd., Sec.4'],
                        City: 'Taipei',
                        StateProvinceCode: '',
                        PostalCode: '10567',
                        CountryCode: 'TW'
                    }
                },
                ShipTo: {
                    Name: 'SMB Name',
                    Address: {
                        AddressLine: '21 ARGONAUT SUITE B',
                        City: 'CHICAGO',
                        StateProvinceCode: '',
                        PostalCode: '60616',
                        CountryCode: 'US'
                    }
                },
                ShipFrom: {
                    Name: 'GCR.inc',
                    Address: {
                        AddressLine: ['13F-2, No. 760, Bade Rd., Sec.4'],
                        City: 'Taipei',
                        StateProvinceCode: '',
                        PostalCode: '10567',
                        CountryCode: 'TW'
                    }
                },
                Package: [
                    {
                        PackagingType: {
                            Code: '02'
                        },
                        Dimensions: {
                            Length: 30,
                            Width: 20,
                            Height: 10,
                            UnitOfMeasurement: {
                                Code: 'CM'
                            }
                        },
                        PackageWeight: {
                            Weight: 10,
                            UnitOfMeasurement: {
                                Code: 'KGS'
                            }
                        }
                    },
//					 {
//						PackagingType: {
//							Code: '02'
//						},
//						Dimensions: {
//							Length: 153,
//							Width: 10,
//							Height: 10,
//							UnitOfMeasurement: {
//								Code: 'CM'
//							}
//						},
//						PackageWeight: {
//							Weight: 30,
//							UnitOfMeasurement: {
//								Code: 'KGS'
//							}
//						}
//					}, {
//						PackagingType: {
//							Code: '02'
//						},
//						Dimensions: {
//							Length: 90,
//							Width: 80,
//							Height: 80,
//							UnitOfMeasurement: {
//								Code: 'CM'
//							}
//						},
//						PackageWeight: {
//							Weight: 40,
//							UnitOfMeasurement: {
//								Code: 'KGS'
//							}
//						}
//					}
                ]
            }
        };
        $timeout(function() {
            $scope.Rate.Shipment.Shipper.Address.StateProvinceCode = '';
            $scope.Rate.Shipment.ShipTo.Address.StateProvinceCode = 'IL';
            $scope.Rate.Shipment.ShipFrom.Address.StateProvinceCode = '';
        }, 1);
    };
    var getDefaultRateData = function() {
        return {
            PickupType: {
                Code: '01'
            },
            Shipment: {
                Service: {
                    Code: '01'
                },
                Shipper: {
                    Name: '',
                    Address: {
                        AddressLine: [''],
                        City: '',
                        StateProvinceCode: '',
                        PostalCode: '',
                        CountryCode: ''
                    }
                },
                ShipTo: {
                    Name: '',
                    Address: {
                        AddressLine: '',
                        City: '',
                        StateProvinceCode: '',
                        PostalCode: '',
                        CountryCode: ''
                    }
                },
                ShipFrom: {
                    Name: '',
                    Address: {
                        AddressLine: [''],
                        City: '',
                        StateProvinceCode: '',
                        PostalCode: '',
                        CountryCode: ''
                    }
                },
                Package: [
                    {
                        PackagingType: {
                            Code: '02'
                        },
                        Dimensions: {
                            Length: 0,
                            Width: 0,
                            Height: 0,
                            UnitOfMeasurement: {
                                Code: 'CM'
                            }
                        },
                        PackageWeight: {
                            Weight: 0,
                            UnitOfMeasurement: {
                                Code: 'KGS'
                            }
                        }
                    }, {
                        PackagingType: {
                            Code: '02'
                        },
                        Dimensions: {
                            Length: 0,
                            Width: 0,
                            Height: 0,
                            UnitOfMeasurement: {
                                Code: 'CM'
                            }
                        },
                        PackageWeight: {
                            Weight: 0,
                            UnitOfMeasurement: {
                                Code: 'KGS'
                            }
                        }
                    }
                ]
            }
        };
    };
});