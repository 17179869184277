app.controller('CheckoutStep3Controller', function($scope, $rootScope, $route, $routeParams, $location, $anchorScroll, Setting, CommercePayment,$modal,Cart) {
    var user = Setting.user;
    var customer = Setting.customer;
    $scope.savepdf = function($filename) {
        html2canvas($("#exportthis"), {
            onrendered: function (canvas) {
                var data = canvas.toDataURL();
                var docDefinition = {
                    content: [{
                        image: data,
                        width: 500,
                    }]
                };
                //pdfMake.createPdf(docDefinition).download("Score_Details.pdf");
                pdfMake.createPdf(docDefinition).download($filename+'.pdf');
            }
        });
    };
    $scope.successPage = function() {
        $scope.updateCart();
    };
    $scope.completedPage = function() {
        $scope.updateCart();
    };
    $scope.updateCart = function() {
        var cart = new Cart;
        cart.$info(function(result) {
            $scope.cart = result.cart || {};
        });
        $rootScope.$broadcast('updateCartWidget');
    };


    //$scope.failurePage = function() {
        // var order_id = $routeParams.order_id;
        // var order = new Order();
        // order.id = order_id;
        // order.$get(function(result) {
        //     $scope.order = result.order || {};
        // }, function(error) {
        // });
    //};
    // $scope.repay = function() {
    //     var order_id = $routeParams.order_id;
    //     var order = new Order();
    //     order.id = order_id;
    //     order.$repay(function(result) {
    //         var html = result.html || '';
    //         $('body').append(html);
    //     }, function(error) {
    //
    //     });
    // };
    $scope.showConfirm = function(ev) {
        var order_number_temp =$routeParams.order_id;
        $modal.open({
            templateUrl: '/view/checkout/modal/confirm_payment.html',
            controller: function($scope, $modalInstance) {
                $scope.payment_options = {};
                $scope.repay = {
                    payment: {
                        option: null
                    },
                    po_number : null,
                    poc_ord   : null
                };
                var payment = new CommercePayment();
                payment.order_number = order_number_temp;
                var html = '';
                var currentPayment = '';
                payment.$getOrder(function(result) {
                    currentPayment = result.order.payment.code;
                }, function(error) {

                });
                payment.$getPayments(function(result) {
                    $scope.payment_options = result.options || {};
                    $scope.repay.payment.option = currentPayment;
                });

                $scope.repaySubmit = function() {
                    var payAgain = new CommercePayment();
                    payAgain.order_number = order_number_temp;
                    payAgain.customer_po  = $scope.repay.po_number;
                    payAgain.poc_ord      = $scope.repay.poc_ord;
                    payAgain.payment = $scope.repay.payment.option;
                    payAgain.$resetPayment(function(result) {

                    });
                    payAgain.$getForm(function(result) {
                        html = result.html || '';
                        $('body').append(html);
                    }, function(error) {

                    });

                };
                $scope.repayCancel = function() {
                    $modalInstance.close();
                };
            },
            resolve: {

            }
        });
    };
});