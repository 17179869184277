app.controller('MenuController', function($scope, $rootScope, Category) {
    $scope.store = function() {
        $('.main-menu.store ul li').hover(function() {
            $('div', this).fadeIn(300);
        }, function() {
            $('div', this).hide();
        });
        var category = new Category();
        category.$all(function(result) {
            $scope.Categories = result.categories || [];
        });
    };
    $scope.manage = function() {
        $rootScope.$watch('menu', function() {
            $scope.accordion = $rootScope.menu;
        });
    };
});