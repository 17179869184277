app.config(function($routeProvider, ACLProvider, TraceProvider) {
    $routeProvider.when('/store/checkout/step1', {
        layout: 'store',
        templateUrl: '/view/checkout/step1/main.html',
        resolve: {
            loggedIn: ACLProvider.checkLoggedIn,
            trace: function() {
                TraceProvider.number('P82030');
            }
        }
    });
    $routeProvider.when('/store/checkout/step2/:jde_order_number', {
        layout: 'store',
        templateUrl: '/view/checkout/step2/main.html',
        resolve: {
            loggedIn: ACLProvider.checkLoggedIn,
            trace: function() {
                TraceProvider.number('P82030');
            }
        }
    });
    $routeProvider.when('/store/checkout/step3/success/:order_id', {
        layout: 'store',
        templateUrl: function($routeParams) {
            return '/store/checkout/success/' + $routeParams.order_id + '/page';
        },
        resolve: {
            loggedIn: ACLProvider.checkLoggedIn,
            trace: function() {
                TraceProvider.number('P82030');
            }
        }
    });
    $routeProvider.when('/store/checkout/step3/completed/:order_id', {
        layout: 'store',
        templateUrl: function($routeParams) {
            return '/store/checkout/completed/' + $routeParams.order_id + '/page';
        },
        resolve: {
            loggedIn: ACLProvider.checkLoggedIn,
            trace: function() {
                TraceProvider.number('P82030');
            }
        }
    });
    $routeProvider.when('/store/checkout/step3/failure/:order_id', {
        layout: 'store',
        templateUrl: function($routeParams) {
            return '/store/checkout/failure/' + $routeParams.order_id + '/page';
        },
        resolve: {
            loggedIn: ACLProvider.checkLoggedIn,
            trace: function() {
                TraceProvider.number('P82030');
            }
        }
    });
//    $routeProvider.when('/confirm/:order_id', {
//        layout: 'store',
//        templateUrl: '/view/checkout/confirm.html',
//        resolve: {
//            loggedIn: ACLProvider.checkLoggedIn,
//            trace: function() {
//                TraceProvider.number('P82031');
//            }
//        }
//    });
    $routeProvider.when('/ups/rate', {
        layout: 'store',
        templateUrl: '/view/ups/rate.html',
        resolve: {
            loggedIn: ACLProvider.checkLoggedIn
        }
    });
    $routeProvider.when('/ups/ship', {
        layout: 'store',
        templateUrl: '/view/ups/ship.html',
        resolve: {
//			loggedIn: ACLProvider.checkLoggedIn
        }
    });
});